<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-attendance @hidden="selectedAttendance = undefined" id="edit-attendance" :attendance="selectedAttendance" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'attendances.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
        </b-col>
      </b-row>
      <b-card class="mb-3" v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <template #label>
               <label class="mb-0">Start Date</label>
              </template>
              <b-form-datepicker @input="load" v-model="dateStart" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <template #label>
                <label class="mb-0">End Date</label>
              </template>
              <b-form-datepicker @input="load" v-model="dateEnd" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-row>
        <b-col cols="12">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table sortable responsive outlined :items="formattedData" :fields="['staff', 'start', 'end', 'length', 'actions']">
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`">
                    <fa-icon icon="pen" />
                  </b-button>
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="trash(row.item.id)" variant="danger" v-b-popover.top.hover="`Delete`">
                    <fa-icon icon="trash" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-list-group>
          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import StickyHeader from '../components/StickyHeader.vue'
import EditAttendance from './Modals/EditAttendance.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";
import moment from "moment";

export default {
  components: { StickyHeader, EditAttendance },
  data() {
    return {
      query: this.$route.query.query ?? '',
      dateStart: this.$route.query.start ? moment(this.$route.query.start) : 0,
      dateEnd: this.$route.query.end ? moment(this.$route.query.end) : 0,
      filtersShown: false,
      selectedAttendance: {},
      formattedData: []
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    ...mapState('attendances', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('attendances', ['fetch', 'delete']),
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'attendances', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'attendances', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedAttendance = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'attendances.update' && this.$route.params.attendanceId === id)) {
        this.$router.push({ name: 'attendances.update', params: { attendanceId: id } });
      }
    },
    trash(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to delete this clock in/out?", {
        title: 'Delete Clock In/Out?',
        okText: 'Yes, Delete',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete(id).then(() => this.load());
      });
    },
    async load() {
      return await this.fetch({
        query: this.query ?? '',
        page: this.$route.query.page || 1,
        start: this.dateStart,
        end: this.dateEnd,
        clock_in_out: 1
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      this.formattedData = map(data, attendance => {

        const minutes = moment(attendance.end).diff(moment(attendance.start), 'minutes');
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;

        return {
          id: attendance.id,
          staff: attendance?.attendable?.name ?? 'Unknown',
          start: attendance.start ? moment(attendance.start).format('DD/MM/YYYY @ HH:mm') : 'Not Set',
          end: attendance.end ? moment(attendance.end).format('DD/MM/YYYY @ HH:mm') : 'Not Set',
          length: (hours || mins) ? `${hours}h ${mins}m` : 'Ongoing'
        }
      })
    }
  }
}
</script>

<style>
</style>
