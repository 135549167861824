<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.attendanceId ? 'View/Update' : 'Create'} Attendance`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="User" :invalid-feedback="getValidationMessage('attendable_id')" :state="!validationErrors.attendable_id">
                <user-search v-model="editedAttendance.attendable_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Date" :invalid-feedback="getValidationMessage('date')" :state="!validationErrors.date">
                <b-form-datepicker v-model="editedAttendance.date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Clock In" :invalid-feedback="getValidationMessage('start')" :state="!validationErrors.start">
                <b-form-timepicker v-model="editedAttendance.start"></b-form-timepicker>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Clock Out" :invalid-feedback="getValidationMessage('end')" :state="!validationErrors.end">
                <b-form-timepicker v-model="editedAttendance.end"></b-form-timepicker>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveAttendance" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.attendanceId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserSearch from '../../components/UserSearch.vue'
import handleErrors from '../../mixins/handleErrors';
import moment from "moment";

export default {
  components: { UserSearch },
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedAttendance: {
        id: null,
        date: null,
        start: null,
        end: null
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    attendance: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.attendanceId && this.attendance) {
      this.loading = true;

      try {
        const attendance = await this.fetchSingle(this.$route.params.attendanceId);

        this.editedAttendance = {
          ...attendance,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load attendance. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('attendances', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedAttendance = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    saveAttendance() {
      this.saving = true;
      this.updateOrCreate({
        ...this.editedAttendance
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch({
          clock_in_out: 1
        });
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    attendance(value) {
      this.editedAttendance = {
        ...value,
        date: value.start,
        start: moment(value.start).format('HH:mm:ss'),
        end: moment(value.end).format('HH:mm:ss'),
      };
    }
  }
}
</script>

<style>

</style>
